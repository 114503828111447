<template>
  <div id="item-grid">
    <BaseScrollbar
      :height="breadcrumbs ? 'calc(100vh - 295px)' : 'calc(100vh - 260px)'"
    >
      <div v-for="{ key, value, data } in items" :key="value">
        <div v-if="key" class="group-by">
          <span class="key">{{ key }}: </span>
          <span>{{ value }} </span>
          <span>({{ data.length }})</span>
        </div>

        <slot name="items" :items="data">
          <div class="row q-col-gutter-md">
            <div
              v-for="item in data"
              :key="item.id"
              class="col-xs-6 col-sm-4 col-md-3 col-lg-3"
              @click="$emit('select', item.id)"
            >
              <div class="card">
                <!-- header -->

                <slot name="header" :item="item">
                  <div class="row items-center q-pa-md">
                    <!-- avatar -->

                    <slot name="avatar" :item="item">
                      <BaseIcon :name="item.icon" color="secondary" />
                    </slot>

                    <!-- ... -->

                    <!-- info -->

                    <div class="col q-mx-md">
                      <!-- title -->

                      <slot name="title" :item="item" :title="item[title.name]">
                        <div class="title ellipsis">
                          {{ item[title.name] }}
                        </div>
                      </slot>

                      <!-- ... -->

                      <!-- subtitle -->

                      <slot
                        name="subtitle"
                        :subtitle="subtitle && item[subtitle.name]"
                      >
                        <div class="subtitle ellipsis">
                          {{ item[subtitle.name] || "-" }}
                        </div>
                      </slot>

                      <!-- ... -->
                    </div>

                    <!-- ... -->

                    <!-- info icon -->

                    <slot v-if="item.infoIcon" name="infoIcon" :item="item">
                      <BaseActionButton
                        v-tooltip.top="item.infoIconTooltip"
                        :icon="item.infoIcon ? item.infoIcon : ''"
                        :color="
                          item.infoIconColor ? item.infoIconColor : 'primary'
                        "
                        is-flat
                        no-border
                        @click="$emit('info', item.id)"
                      />
                    </slot>

                    <!-- ... -->
                  </div>
                </slot>

                <!-- ... -->

                <!-- content -->

                <slot name="content" :item="item">
                  <template v-if="_columns.length">
                    <BaseSeparator has-inset />

                    <div class="q-py-sm">
                      <div v-for="column in _columns" :key="column.id">
                        <slot
                          :name="column.name"
                          :column="column.label"
                          :value="item[column.name]"
                          :item="item"
                        >
                          <div class="row q-px-md q-py-sm">
                            <div class="label ellipsis medium">
                              {{ column.label }}
                            </div>

                            <q-space />

                            <template v-if="column.dataType === 'FILE_UPLOAD'">
                              <div class="ellipsis medium text-right">
                                <div
                                  v-for="(file, index) in item[column.name]"
                                  :key="index"
                                  class="row"
                                >
                                  <FileIcon
                                    :mime-type="fileType(file.fileName)"
                                    class="mini-avatar col-auto q-mr-sm"
                                  />
                                  <span
                                    class="text-secondary file-underline col"
                                    @click="viewFile(file, item)"
                                    >{{ file.fileName }}</span
                                  >
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <div class="ellipsis medium text-right">
                                {{ item[column.name] || "-" }}
                              </div>
                            </template>
                          </div>
                        </slot>
                      </div>
                    </div>
                  </template>
                </slot>

                <!-- ... -->

                <BaseSeparator v-if="!noActions" has-inset />

                <!-- footer -->

                <slot v-if="!noActions" name="footer" :item="item">
                  <template v-if="mode === 'BROWSE'">
                    <template v-if="!item.actionAccess">
                      <div class="row items-center">
                        <template v-if="security || visibility(item)">
                          <template v-if="item.actionButton">
                            <!-- edit -->

                            <Action
                              icon="eva-edit-outline"
                              class="col"
                              @click.stop="$emit('edit', item.id)"
                            />

                            <!-- ... -->

                            <BaseSeparator is-vertical has-inset />

                            <!-- security -->

                            <Action
                              icon="eva-shield-outline"
                              class="col"
                              @click.stop="$emit('secure', item.id)"
                            />

                            <!-- ... -->

                            <BaseSeparator is-vertical has-inset />

                            <!-- delete -->

                            <Action
                              icon="eva-trash-2-outline"
                              class="col"
                              @click.stop="$emit('delete', item.id)"
                            />

                            <!-- ... -->

                            <BaseSeparator is-vertical has-inset />

                            <!-- action -->

                            <BaseActionButton
                              is-flat
                              icon="eva-more-vertical"
                              no-border
                              class="q-mx-md"
                              @click.stop="$emit('more', item.id)"
                            />

                            <!-- ... -->
                          </template>

                          <template v-else>
                            <!-- edit -->

                            <Action
                              icon="eva-edit-outline"
                              label="Edit"
                              class="col"
                              @click.stop="$emit('edit', item.id)"
                            />

                            <!-- ... -->

                            <BaseSeparator is-vertical has-inset />

                            <!-- delete -->

                            <Action
                              icon="eva-trash-2-outline"
                              label="Delete"
                              class="col"
                              @click.stop="$emit('delete', item.id)"
                            />

                            <!-- ... -->

                            <BaseSeparator is-vertical has-inset />

                            <!-- export -->
                            <template v-if="showExport">
                              <BaseActionButton
                                is-flat
                                icon="eva-download-outline"
                                no-border
                                class="q-mx-sm"
                                @click.stop="$emit('export', item.id)"
                              />

                              <!-- ... -->

                              <BaseSeparator is-vertical has-inset />
                            </template>
                            <!-- action -->

                            <BaseActionButton
                              is-flat
                              icon="eva-more-vertical"
                              no-border
                              class="q-mx-md"
                              @click.stop="$emit('more', item.id)"
                            />

                            <!-- ... -->
                          </template>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <Action
                        icon="eva-eye-outline"
                        class="col"
                        label="View"
                        @click.stop="$emit('select', item.id)"
                      />
                    </template>
                  </template>

                  <template v-if="!visibility(item) && !security">
                    <!-- only view -->
                    <template>
                      <Action
                        icon="mdi-eye-outline"
                        label="View"
                        @click.stop="$emit('select', item.id)"
                      />
                    </template>

                    <!-- ... -->
                  </template>

                  <template v-if="mode === 'TRASH'">
                    <!-- restore -->

                    <template v-if="security || visibility(item)">
                      <Action
                        icon="mdi-history"
                        label="Restore"
                        @click.stop="$emit('restore', item.id)"
                      />
                    </template>

                    <!-- ... -->
                  </template>
                </slot>

                <!-- ... -->
              </div>
            </div>
          </div>
        </slot>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import Action from "@/components/common/Action.vue";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "ItemGrid",

  components: { Action, FileIcon },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    security: {
      type: Boolean,
      default: true,
    },

    noActions: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Boolean,
      default: false,
    },

    showExport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userId: this.$store.state.session.id,
      email: this.$store.state.session.email,
    };
  },

  computed: {
    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    subtitle() {
      return this.columns.find((column) => column.type === "SUBTITLE");
    },

    _columns() {
      return this.columns.filter(
        (column) => column.type === "NORMAL" && column.isVisible
      );
    },
  },

  methods: {
    visibility(item) {
      const userId = Number(this.userId);
      const email = this.email;
      let hasMatch = false;
      const ownerIds = item.owner ? item.owner.map((owner) => owner.id) : [0];
      const coordinatorIds = item.coordinator
        ? item.coordinator.map((coordinator) => coordinator.id)
        : [0];
      if (
        item.createdBy === email ||
        ownerIds.includes(userId) ||
        coordinatorIds.includes(userId) ||
        userId === 1
      ) {
        hasMatch = true;
      }
      return hasMatch;
    },

    viewFile(file, item) {
      file.createdBy = item.createdBy;
      file.createdAt = item.createdAt;

      this.$emit("viewFile", file);
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },
  },
};
</script>

<style lang="scss">
#item-grid {
  .group-by {
    color: var(--deep-orange);
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 500;

    .key {
      color: var(--icon-color);
      text-transform: capitalize;
    }
  }

  .card {
    background-color: var(--component-bg-color);
    border-radius: 4px;
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    .title {
      @extend .title-2;
      text-transform: capitalize;
    }

    .subtitle {
      color: var(--icon-color-inverted);
    }

    .label {
      color: var(--icon-color-inverted);
    }

    .medium {
      width: 50%;
    }

    .ellipsis:hover {
      max-width: none !important;
      overflow: visible;
      white-space: pre;
    }

    .stat {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      transition: all 0.25s ease;

      .label {
        color: var(--icon-color-inverted);
        text-decoration: underline;
      }

      &:hover {
        cursor: pointer;
        color: var(--secondary);
        background-color: var(--hover-bg-color);

        .label {
          color: var(--secondary);
        }
      }
    }

    .file-underline {
      text-decoration: underline;
    }

    .mini-avatar {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
